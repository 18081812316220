@tailwind base;
@tailwind components;
@tailwind utilities;

@import './blogs.css';
@import '../jiraaf-ui/styles/fonts.css';
@import '../jiraaf-ui/styles/design-system.css';
@import url('./html-formatter.css');

/* more styles */
@import url('@fortawesome/fontawesome-svg-core/styles.css');
@import url('react-toastify/dist/ReactToastify.css');

/* phone input */
@import url('react-phone-input-2/lib/style.css');
@import url('./html-formatter.css');

body {
    font-family: 'GeneralSans', sans-serif;
    margin: 0 auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: 0.025em;
}

:root {
    --header-height: 64px;
    --header-height-mobile: 48px;
    --bottom-navigation-height: 70px;
}

@layer components {
    .min-h-calc-vh-without-header-investor-layout {
        min-height: calc(100vh - var(--header-height));
    }
    .min-h-calc-vh-without-header-tab-and-mobile {
        min-height: calc(100svh - var(--header-height-mobile));
    }
    .min-h-calc-vh-home-page-banner-web {
        min-height: calc(100svh - var(--header-height));
    }
    .min-h-calc-vh-home-page-banner-tab-and-mobile {
        min-height: calc(100svh - var(--header-height-mobile));
    }

    .min-h-calc-vh-screen-plus-bottom-navigation {
        min-height: calc(100svh + var(--bottom-navigation-height));
    }

    .min-h-calc-vh-screen-minus-bottom-navigation {
        min-height: calc(100svh - var(--bottom-navigation-height));
    }
}

body::-webkit-scrollbar {
    display: none;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px theme('colors.basicWhite') inset !important;
}

.gray_100_autofill:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px theme('colors.gray.100') inset !important;
}

.react-tel-input .flag-dropdown {
    background-color: theme('colors.basicWhite') !important;
}

.react-tel-input.disabled-phone-input .flag-dropdown {
    background-color: theme('colors.gray.100') !important;
}

html {
    scroll-behavior: smooth;
}

.activeNavLink {
    position: relative;
}

.activeNavLink::before {
    content: '';
    position: absolute;
    width: 88px;
    height: 3px;
    bottom: -34px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    @apply bg-primary-500;
}

.sidebar-element {
    width: 255px;
    @apply h-12 p-3 my-2 cursor-pointer text-gray-400;
}

.sidebar-element-active {
    @apply text-basicWhite rounded-l-lg bg-gray-900;
}

.sidebar-child-element {
    @apply px-3 my-5 ml-8 cursor-pointer text-gray-400;
}

.sidebar-child-element-active {
    @apply text-secondary-200 border-l border-secondary-200;
}

.drop-down-checkbox {
    @apply mr-3;
}

input[type='radio'].radio-large {
    transform: scale(1.5);
}

/* Signup/Login Page Background */
.signup-opportunity-page-bg {
    background-image: url('/images/background-bubble.svg');
}

@layer components {
    .signup-page-bg {
        background-image: url('/images/Vector.svg');
    }
}

.signup-section2-bg {
    background-image: url('/images/wave1.svg');
}

@media (max-width: 767px) {
    .survey-bg-mobile {
        background-image: url('/images/survey-bg-mobile.svg');
        background-size: cover;
    }
}

.signup-section3-bg {
    background-image: url('/images/wave3.svg');
}

.blue_color {
    @apply bg-primary-500;
}

.scrollbar::-webkit-scrollbar {
    display: none;
}

.scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.disabled {
    opacity: 0.5;
    @apply bg-primary-100 text-primary-300;
}

@media (min-width: 1200px) and (max-width: 1400px) {
    .bg-position {
        background-size: 100% 100%;
        background-position: -2px -29px !important;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

.heading {
    @apply text-gray-900;
}

.opportunity-summary-tag {
    font-size: 10.5px;
}

.opportunities-overview-tabs {
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
}

.header-username {
    max-width: 200px;
}

.homepage-header .header-action-button {
    line-height: 1.2;
}

@media (min-width: 768px) {
    .homepage-header .header-action-button {
        line-height: 1.6;
    }
}

.stickyheader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    box-sizing: border-box;
    padding: 20px;
    transition: 0.25s ease-in-out;
    z-index: 50;
}

.navbar-sticky {
    box-shadow: 0px 14px 44px rgba(0, 0, 0, 0.09);
    backdrop-filter: blur(54px);
}

.page-wrapper {
    padding-top: 48px;
}

@media (min-width: 768px) {
    .page-wrapper {
        padding-top: 64px;
        max-width: 1440px;
        margin: 0 auto;
    }
}

@media (min-width: 768px) {
    .signup-page-wrapper {
        max-width: 1440px;
        margin: 0 auto;
    }
}

table.tables td {
    padding: 10px;
    text-align: left;
    @apply border-1 border-solid border-gray-100;
}

.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 28px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.gray-switch {
    position: relative;
    display: inline-block;
    width: 39px;
    height: 24px;
}

.gray-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    box-shadow: 0 0 1px theme('colors.secondary[500]');
    @apply bg-secondary-500;
}

.slider:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider:before {
    -webkit-transform: translateX(17px);
    -ms-transform: translateX(17px);
    transform: translateX(17px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.gray-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    box-shadow: 0 0 1px theme('colors.gray[100]');
    @apply bg-gray-100;
}

.gray-slider:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 3px;
    background-color: theme('colors.gray[500]');
    @apply bg-gray-500;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .gray-slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
}

/* Roundegray-d sliders */
.gray-slider.round {
    border-radius: 34px;
    border: 1px solid theme('colors.gray[500]');
}

.gray-slider.round:before {
    border-radius: 50%;
    border: 1px solid theme('colors.gray[500]');
}

.mobile-menu .mobile-menu-content {
    transition: transform 0.2s ease-in-out;
}

.mobile-menu .bg-blend-overlay {
    transition: opacity 0.1s ease-in-out;
}

.mobile-menu-hidden .mobile-menu-content {
    transform: translate3d(100%, 0, 0);
}

.mobile-menu-visible .mobile-menu-content {
    transform: translate3d(0, 0, 0);
}

.mobile-menu-hidden .bg-blend-overlay {
    transform: translate3d(-100%, 0, 0);
    opacity: 0;
}

.mobile-menu-visible .bg-blend-overlay {
    transform: translate3d(0, 0, 0);
    opacity: 1;
}

.clipping {
    clip-path: ellipse(100% 95% at 50% 0%);
}

@media (max-width: 1024px) {
    .clipping {
        clip-path: ellipse(200% 95% at 50% 0%);
    }
}

.imageWrapper {
    transform-style: preserve-3d;
    perspective: 1000px;
}

.card-front {
    transform: rotateX(0deg);
    transition: 0.5s ease-in all;
    backface-visibility: hidden;
    @apply bg-basicWhite;
}

.card-back {
    transform: rotateX(180deg);
    transition: 0.5s ease-in all;
    backface-visibility: hidden;
}

.card:hover .card-front {
    transform: rotateX(-180deg);
}

.card:hover .card-back {
    transform: rotateX(0deg);
    opacity: 1;
}

.team-grid li {
    grid-column: span 2;
}

/* Dealing with single orphan */
@media (max-width: 768px) {
    .team-grid li:last-child:nth-child(2n + 1) {
        grid-column-end: 4;
    }
}

@media (min-width: 768px) and (max-width: 1279px) {
    /* Dealing with three orphans */

    .team-grid li:last-child:nth-child(4n + 3) {
        grid-column-end: 8;
    }

    .team-grid li:nth-last-child(2):nth-child(4n + 2) {
        grid-column-end: 6;
    }

    .team-grid li:nth-last-child(3):nth-child(4n + 1) {
        grid-column-end: 4;
    }

    /* Dealing with two orphans */

    .team-grid li:last-child:nth-child(4n + 2) {
        grid-column-end: 7;
    }

    .team-grid li:nth-last-child(2):nth-child(4n + 1) {
        grid-column-end: 5;
    }

    /* Dealing with single orphan */

    .team-grid li:last-child:nth-child(4n + 1) {
        grid-column-end: 6;
    }
}

@media (min-width: 1280px) {
    /* Dealing with four orphans */
    .team-grid li:last-child:nth-child(5n + 4) {
        grid-column-end: 10;
    }

    .team-grid li:last-child(2):nth-child(5n + 3) {
        grid-column-end: 8;
    }

    .team-grid li:nth-last-child(3):nth-child(5n + 2) {
        grid-column-end: 6;
    }

    .team-grid li:nth-last-child(4):nth-child(5n + 1) {
        grid-column-end: 4;
    }

    /* Dealing with three orphans */

    .team-grid li:last-child:nth-child(5n + 3) {
        grid-column-end: 9;
    }

    .team-grid li:nth-last-child(2):nth-child(5n + 2) {
        grid-column-end: 7;
    }

    .team-grid li:nth-last-child(3):nth-child(5n + 1) {
        grid-column-end: 5;
    }

    /* Dealing with two orphans */

    .team-grid li:last-child:nth-child(5n + 2) {
        grid-column-end: 8;
    }

    .team-grid li:nth-last-child(2):nth-child(5n + 1) {
        grid-column-end: 6;
    }

    /* Dealing with single orphan */

    .team-grid li:last-child:nth-child(5n + 1) {
        grid-column-end: 7;
    }
}

@media (max-width: 640px) {
    .card-back {
        transform: rotateX(0deg);
        opacity: 0;
    }

    .card:hover .card-front {
        transform: rotateX(0deg);
        opacity: 0;
    }

    .card:hover .card-back {
        opacity: 1;
    }
}

.selected-flag .flag {
    transform: scale(1.5);
}

.selected-flag {
    padding-left: 1rem !important;
    width: 100% !important;
}

.country-list-container::-webkit-scrollbar {
    -webkit-appearance: none;
}

.country-list-container::-webkit-scrollbar:vertical {
    width: 12px;
}

.country-list-container::-webkit-scrollbar:horizontal {
    height: 12px;
}

.country-list-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    @apply bg-primary-500 border-2 border-solid border-basicWhite;
}

.country-list-container::-webkit-scrollbar-track {
    border-radius: 10px;
    @apply bg-basicWhite;
}

.country-list-container {
    scrollbar-color: theme('colors.primary[500]') theme('colors.basicWhite');
    scrollbar-width: thin;
}

.country-list-container-deactivate-mobile {
    height: auto !important;
}

/* digio style  */
div[id^='parentdigio-ifm-'],
div[id*='parentdigio-ifm-'] {
    overflow: auto !important;
    min-width: 0 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    top: 0px !important;
    height: 100dvh !important;
}

div[id^='wrapperdigio-ifm-'],
div[id*='wrapperdigio-ifm-'] {
    overflow: auto !important;
    min-width: 0 !important;
    width: 100% !important;
    max-width: 800px !important;
    left: auto !important;
    height: 100% !important;
    top: 0px !important;
    padding-top: 50px !important;
}

div[id^='wrapperdigio-ifm-'] img,
div[id*='wrapperdigio-ifm-'] img {
    z-index: 1 !important;
    top: 0px !important;
}

div[id^='wrapperdigio-ifm-'] iframe,
div[id*='wrapperdigio-ifm-'] iframe {
    overflow: auto !important;
    min-width: 0 !important;
    width: 100% !important;
    height: 100% !important;
    transform: scale(1) !important;
    top: 0px !important;
}

@media (max-width: 321px) {
    div[id^='wrapperdigio-ifm-'],
    div[id*='wrapperdigio-ifm-'] {
        height: 100% !important;
        padding-top: 0px !important;
    }
    div[id^='wrapperdigio-ifm-'] iframe,
    div[id*='wrapperdigio-ifm-'] iframe {
        height: 100% !important;
        transform: scale(0.8) !important;
        top: 10px !important;
    }
}

@media (min-width: 767px) and (max-width: 1023px) {
    div[id^='parentdigio-ifm-'],
    div[id*='parentdigio-ifm-'] {
        max-height: 100dvh !important;
    }

    div[id^='wrapperdigio-ifm-'],
    div[id*='wrapperdigio-ifm-'] {
        padding-top: 0px !important;
        max-height: 100dvh !important;
    }

    div[id^='wrapperdigio-ifm-'] iframe,
    div[id*='wrapperdigio-ifm-'] iframe {
        transform: scale(1) !important;
    }
}

@media (max-width: 768px) {
    div[id^='parentdigio-ifm-'],
    div[id*='parentdigio-ifm-'] {
        background: white !important;
        height: 100% !important;
    }
}

@media (min-width: 1024px) and (max-width: 1280px) {
    div[id^='wrapperdigio-ifm-'] iframe,
    div[id*='wrapperdigio-ifm-'] iframe {
        height: 100% !important;
    }
}

@media (min-width: 1024px) and (max-width: 1280px)  and (max-height: 768px) and (orientation: landscape) {
    div[id^='wrapperdigio-ifm-'] iframe,
    div[id*='wrapperdigio-ifm-'] iframe {
        padding-top: 0px !important;    

    }
}

@media (min-width: 1024px)and (orientation: portrait) {
    div[id^='parentdigio-ifm-'],
    div[id*='parentdigio-ifm-'] {
        overflow: inherit !important;
        max-height: none !important;
        height: 100%;
    }

    div[id^='wrapperdigio-ifm-'],
    div[id*='wrapperdigio-ifm-'] {
        height: 650px !important;
        overflow: inherit;
        min-height: 100dvh !important;
    }

    div[id^='wrapperdigio-ifm-'] iframe,
    div[id*='wrapperdigio-ifm-'] iframe {
        transform: scale(1) !important;
    }
}

/* digio style -- End  */

.opportunityDetails b {
    font-weight: 600;
}

.opportunityDetails .font-semibold {
    font-weight: 600;
}

.border-below {
    background: linear-gradient(
            90deg,
            transparent 45%,
            theme('colors.secondary[500]') 45%,
            theme('colors.secondary[500]') 55%,
            transparent 55%
        ),
        linear-gradient(90deg, transparent 0%, rgb(255, 255, 255) 0%);
    background-size: 100% 2px, 100% 100%;
    background-position: 0% 100%, 0% -5px;
    background-repeat: no-repeat;
}

.border-below-left {
    background: linear-gradient(
            90deg,
            transparent 0%,
            theme('colors.secondary[500]') 0%,
            theme('colors.secondary[500]') 4%,
            transparent 4%
        ),
        linear-gradient(90deg, transparent 0%, rgb(255, 255, 255) 0%);
    background-size: 100% 2px, 100% 100%;
    background-position: 0% 100%, 0% -5px;
    background-repeat: no-repeat;
}

.mobile-menu .mobile-menu-content {
    transition: transform 0.2s ease-in-out;
}

.mobile-menu .bg-blend-overlay {
    transition: opacity 0.1s ease-in-out;
}

.mobile-menu-hidden .mobile-menu-content {
    transform: translate3d(100%, 0, 0);
}

.mobile-menu-visible .mobile-menu-content {
    transform: translate3d(0, 0, 0);
}

.mobile-menu-hidden .bg-blend-overlay {
    transform: translate3d(-100%, 0, 0);
    opacity: 0;
}

.mobile-menu-visible .bg-blend-overlay {
    transform: translate3d(0, 0, 0);
    opacity: 1;
}

.clipping {
    clip-path: ellipse(75% 100% at 50% 0%);
}

@media (max-width: 1024px) {
    .clipping {
        clip-path: ellipse(100% 95% at 50% 0%);
    }
}

@media (min-width: 1025px) and (max-width: 1560px) {
    .clipping {
        clip-path: ellipse(80% 95% at 50% 0%);
    }
}

.curved {
    position: relative;
}

.curved:after {
    position: absolute;
    content: '';
    left: -50px;
    right: -50px;
    bottom: 0;
    height: 150px;
    border-radius: 60% 60% 0 0;
    z-index: 0;
    @apply bg-bgColor-50;
}

.focus-gradient {
    background: linear-gradient(
        270.13deg,
        theme('colors.skyBlue.100') -1.08%,
        theme('colors.skyBlue.50') 99.89%
    );
    box-shadow: inset 0px -1px 0px theme('colors.gray[100]');
}

.country-list-container::-webkit-scrollbar {
    -webkit-appearance: none;
}

.country-list-container::-webkit-scrollbar:vertical {
    width: 12px;
}

.country-list-container::-webkit-scrollbar:horizontal {
    height: 12px;
}

.country-list-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    @apply bg-primary-500 border-2 border-solid border-basicWhite;
}

.country-list-container::-webkit-scrollbar-track {
    border-radius: 10px;
    @apply bg-basicWhite;
}

.country-list-container {
    scrollbar-color: theme('colors.primary[500]') theme('colors.basicWhite');
    scrollbar-width: thin;
}

.drop-down-item {
    @apply flex p-2 font-light;
}

.admin-date-input[type='date']::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

/* width */
::-webkit-scrollbar {
    width: 26px;
    border-radius: 13px;
    background-clip: padding-box;
    border: 10px solid transparent;
}

/* Track */
::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 100px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    width: 15px;
    border-radius: 13px;
    background-clip: padding-box;
    border: 10px solid transparent;
    box-shadow: inset 0 0 0 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar,
::-webkit-scrollbar-thumb {
    width: 26px;
    border-radius: 13px;
    background-clip: padding-box;
    border: 10px solid transparent;
}

::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 10px rgba(0, 0, 0, 0.3);
}

.formio-jiraaf {
    font-size: 14px;
}

.formio-jiraaf .pagination {
    display: none;
}

.col-form-label {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    @apply text-primary-700;
}

.formio-form div.form-radio {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    margin-top: 0.5rem;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    padding: 10px 3px 0px 20px;
}

@media (min-width: 320px) and (max-width: 768px) {
    .col-form-label {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        @apply text-primary-700;
    }

    .formio-form div.form-radio {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        margin-top: 0.5rem;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        padding: 10px 3px 16px 20px;
    }
}

.formio-form p.question-number {
    font-size: 1rem;
    opacity: 60%;
}

.formio-form div.form-radio .radio.form-check {
    flex: 1 0 20%;
}

.formio-form div.form-radio .checkbox.form-check {
    flex: 1 0 20%;
}

@media (min-width: 767px) {
    .formio-component-modal-wrapper.has-error .invalid-feedback,
    .formio-component-modal-wrapper.has-message .invalid-feedback,
    .formio-component.alert-danger .invalid-feedback,
    .formio-component.has-error .invalid-feedback,
    .formio-component.has-message .invalid-feedback {
        padding-left: 20px;
    }
}

@media (min-width: 320px) and (max-width: 768px) {
    .formio-component-modal-wrapper.has-error .invalid-feedback,
    .formio-component-modal-wrapper.has-message .invalid-feedback,
    .formio-component.alert-danger .invalid-feedback,
    .formio-component.has-error .invalid-feedback,
    .formio-component.has-message .invalid-feedback {
        padding-left: 5px;
    }
}

.survey-3 div.form-radio .radio.form-check {
    flex: none !important;
    width: 31%;
}

.survey-3 div.form-radio {
    gap: 23px;
    line-height: 40px;
}

.survey-3 div.form-radio {
    justify-content: flex-start !important;
}

@media (min-width: 641px) and (max-width: 1445px) {
    .survey-3 div.form-radio .radio.form-check {
        width: 30%;
    }
}

.formio-component .formio-component-checkbox {
    padding: 10px 3px 0px 20px;
    font-size: 14px;
    line-height: 20px;
}

@media (min-width: 320px) and (max-width: 767px) {
    .survey-3 div.form-radio .radio.form-check {
        width: 100%;
    }

    .formio-form div.form-radio {
        display: block;
        padding-left: 5px;
    }

    .formio-component .formio-component-checkbox {
        padding: 8px 0px;
    }

    .formio-form div.form-radio .checkbox.form-check {
        padding: 8px 0px;
    }

    .formio-form div.form-radio .radio.form-check label {
        display: flex;
        flex-direction: row;
    }

    .formio-form div.form-radio .checkbox.form-check label {
        display: flex;
        flex-direction: row;
    }

    .formio-component-columns {
        flex-direction: column;
        gap: 5px !important;
        padding-right: 0px;
    }

    .formio-component-columns .col-md-6 {
        width: 100% !important;
    }

    .formio-component-page1Columns4Selectyourprofessionalstatus {
        margin: 0.8rem 0 !important;
    }
}

.form-text .error {
    padding-left: 4px;
}

.formio-select-autocomplete-input {
    display: none;
}

.form-check .checkbox {
    flex: 1 0 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.formio-component .formio-component-checkbox .form-check-label {
    display: flex;
}

.formio-component .formio-component-checkbox input[type='checkbox'] {
    margin-right: 8px;
    margin-top: 2px;
    border-radius: 4px;
    transform: scale(1);
}

.formio-form div.form-radio input[type='radio'] {
    transform: scale(1);
    margin-right: 8px;
    margin-top: 2px;
}

.formio-form div.form-radio input[type='checkbox'] {
    transform: scale(1);
    margin-right: 8px;
    margin-top: 2px;
    border-radius: 4px;
}

@media (min-width: 220px) and (max-width: 768px) {
    .formio-form div.form-radio input[type='radio'] {
        transform: scale(1);
        margin-right: 8px;
        margin-top: 2px;
    }

    .formio-form div.form-radio input[type='checkbox'] {
        transform: scale(1);
        margin-right: 8px;
        margin-top: 2px;
        border-radius: 4px;
    }
}

.formio-form .checkbox.form-check span {
    @apply text-gray-900;
}

.form-group input[type='text'],
.formio-component-textarea textarea {
    border-color: rgba(209, 209, 219);
    border-width: 2px;
    border-radius: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    width: 100%;
    margin: 0.8rem 0 0 0;
}

.formio-jiraaf .alert {
    display: none;
}

.formio-jiraaf .form-group {
    margin-top: 2rem;
}

.formio-jiraaf .other-inputs {
    margin-top: 0;
}

.formio-choices.form-group {
    border: 2px solid rgba(209, 209, 219);
    padding: 9px 4px 4px 8px !important;
    padding-bottom: 4px;
    border-radius: 0.5rem;
    margin: 0.8rem 0;
}

.field-required:after {
    display: none;
}

.choices__list--dropdown {
    left: 0px;
}

.dropdown-icon {
    background-image: url('/images/dropdown.svg');
    background-repeat: no-repeat;
    background-position: right 15px center;
}

.formio-component-columns {
    display: flex;
    gap: 15px;
    padding-right: 10px;
}

.formio-component-columns > .col-md-6 {
    width: 50%;
}

.formio-component-page1Columns4Selectyourprofessionalstatus {
    margin-top: 29px;
}

.survey-form-standalone-page .formio-selectbox-wrapper .form-radio.radio {
    grid-template-columns: none;
}

.survey-form-standalone-page .formio-selectbox-wrapper .form-check-input {
    margin-top: 0 !important;
}

.survey-form-standalone-page .formio-selectbox-wrapper .form-check-label {
    display: flex;
    align-items: center;
}

.survey-form-standalone-page label.col-form-label {
    @apply p3-semibold text-primary-500;
}

.survey-form-standalone-page .formio-radio-textbox-input label.col-form-label,
.survey-form-standalone-page .formio-survey-radio div.radio.form-check label,
.survey-form-standalone-page .formio-selectbox-wrapper .form-check-label {
    @apply p5-regular text-gray-900;
}

.survey-form-standalone-page .formio-selectbox-wrapper input[type='checkbox'],
[type='radio'] {
    @apply text-primary-500;
    border-color: theme('colors.primary.500');
}

.survey-form-standalone-page .form-group input[type='text'],
.formio-component-textarea textarea {
    border-width: 1px;
}

.survey-form-standalone-page .formio-errors.invalid-feedback {
    padding-left: 16px !important;
    margin-top: 0px;
}

.survey-form-standalone-page .formio-form div.form-radio {
    padding: 12px 14px 0px 18px !important;
    margin-top: 0px;
}

.survey-form-standalone-page .formio-errors .error {
    @apply p5-regular;
    margin-top: 12px;
}

.survey-form-standalone-page .formio-component-columns {
    gap: 0;
}

@media (min-width: 320px) and (max-width: 767px) {
    .survey-form-standalone-page label.col-form-label {
        @apply p4-semibold text-primary-500;
    }
    .survey-form-standalone-page
        .formio-form
        div.form-radio
        .checkbox.form-check {
        padding: 0;
    }

    .survey-form-standalone-page .formio-selectbox-wrapper .form-radio.radio {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
}

@media (min-width: 768px) {
    .survey-form-standalone-page .formio-form .usersurvey-question-layout {
        padding: 24px 40px 24px 40px !important;
        margin: 16px 0 !important;
    }
}

@media (max-width: 767px) {
    .survey-form-standalone-page .formio-form .usersurvey-question-layout {
        padding: 16px 16px 8px 16px !important;
        margin: 16px 0 !important;
    }
}

.shine::before {
    content: '';
    position: absolute;
    width: 5rem;
    height: 100%;
    background-image: linear-gradient(
        120deg,
        rgba(255, 255, 255, 0) 30%,
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0) 70%
    );
    top: 0;
    left: 0;
    animation: shine 2.5s infinite linear;
    /* Animation */
}

@keyframes shine {
    0% {
        left: -5rem;
    }

    100% {
        left: 50%;
    }
}

@media (max-width: 1445px) {
    .w-748 {
        width: 600px;
    }

    .button-334 {
        width: 16.875rem !important;
    }

    .formio-component-page1Columns4Selectyourprofessionalstatus {
        margin-top: 0px;
    }

    .survey-thankyou-screen {
        gap: 15px;
    }
}

.animate-scale {
    animation: 1s scaleIn 2s ease-in-out infinite alternate;
}

@keyframes scaleIn {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

@media (min-width: 320px) and (max-width: 950px) {
    .button-334 {
        width: 100% !important;
    }
}

.webinar > div {
    @apply border-1 border-solid border-gray-200;
}

@media (min-width: 768px) {
    .webinar > div:nth-child(-n + 3) {
        border-bottom-width: 1px;
    }

    .webinar > div {
        border-right-width: 1px;
    }

    .webinar > div:nth-child(3n),
    .webinar > div:last-child {
        border-right-width: 0 !important;
    }
}

.alert .alert-danger {
    display: none !important;
}

.formio-form .prelogin-survey-radio div.form-radio {
    display: grid;
    grid-template-columns: auto;
}

.formio-form .form-check-survey div.form-radio {
    display: grid;
    grid-template-columns: auto;
}

.formio-form .usersurvey-text-question.formio-error-wrapper {
    @apply !bg-basicWhite !border-basicWhite;
}

@media (min-width: 768px) {
    .formio-form .usersurvey-question-layout {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 24px 40px 0 40px;
        border-radius: 16px;
        margin: 12px 0;
        @apply !bg-basicWhite;
    }
}

@media (max-width: 768px) {
    .formio-form .usersurvey-question-layout {
        display: flex;
        flex-direction: column;
        width: 100%;
        font-size: 16px;
        padding: 24px 16px 8px 16px;
        border-radius: 16px;
        margin: 12px 0;
        @apply !bg-basicWhite;
    }
}

.formio-form .prelogin-survey-radio.formio-error-wrapper {
    @apply !bg-basicWhite !border-basicWhite;
}

.formio-form .prelogin-survey-radio label.col-form-label.field-required::after {
    content: '*' !important;
    display: inline-block !important;
    @apply text-primary-700;
}

.formio-form .form-check-survey.formio-error-wrapper {
    background-color: theme('colors.basicWhite') !important;
    border-color: theme('colors.basicWhite') !important;
}

.formio-form .form-check-survey label.col-form-label.field-required::after {
    content: '*' !important;
    display: inline-block !important;
    /* TODO: Need to replace this color from colors in desin system */
    color: #00172f;
}

.formio-form
    .usersurvey-text-question
    label.col-form-label.field-required::after {
    content: '*' !important;
    display: inline-block !important;
    @apply text-primary-700;
}

.formio-form .prelogin-survey-radio div.form-radio .radio.form-check {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.formio-form .form-check-survey div.form-radio .checkbox.form-check {
    display: flex;
    align-items: center;
    font-size: 14px;
}

@media (min-width: 768px) {
    .formio-form .prelogin-survey-radio div.form-radio {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        flex-wrap: wrap;
        padding-bottom: 10px;
    }

    .formio-form
        .prelogin-survey-radio
        div.form-radio
        .radio.form-check
        .form-check-label {
        display: flex;
    }

    .formio-form .form-check-survey div.form-radio {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        flex-wrap: wrap;
        padding-bottom: 10px;
    }

    .formio-form
        .form-check-survey
        div.form-radio
        .checkbox.form-check
        .form-check-label {
        display: flex;
    }

    .formio-form
        .form-check-survey-two-col-radio
        div.form-radio
        .checkbox.form-check {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .formio-form
        .form-check-survey-two-col-radio
        div.form-radio
        .checkbox.form-check {
        display: flex;
        align-items: center;
    }

    .formio-form
        .form-check-survey-two-col-radio
        div.form-radio
        .checkbox.form-check
        .form-check-label {
        display: flex;
    }

    .formio-form
        .prelogin-survey-two-col-radio
        div.form-radio
        .radio.form-check {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .formio-form
        .prelogin-survey-two-col-radio
        div.form-radio
        .radio.form-check {
        display: flex;
        align-items: center;
    }

    .formio-form
        .prelogin-survey-two-col-radio
        div.form-radio
        .radio.form-check
        .form-check-label {
        display: flex;
    }
}

.formio-form .prelogin-survey-radio div.form-radio .radio.form-check {
    display: flex;
    align-items: center;
}

.bouncing-loader {
    display: flex;
    justify-content: center;
}

.bouncing-loader > div {
    width: 10px;
    height: 10px;
    margin: 3px 6px;
    border-radius: 50%;
    opacity: 1;
    animation: bouncing-loader 0.6s infinite alternate;
    @apply bg-primary-500;
}

.credit-evaluation-process-video iframe {
    border-radius: 12px;
}

@keyframes bouncing-loader {
    to {
        opacity: 0.1;
        transform: translateY(-10px);
    }
}

.bouncing-loader > div:nth-child(2) {
    animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
    animation-delay: 0.4s;
}

.position-fixed {
    position: fixed !important;
}

.red-star {
    color: black;
    font-size: 80%;
    position: relative;
    top: -3px;
}

.borrower-sublimit-search input {
    box-shadow: none !important;
}

.location-select-dropdown-post-login {
    position: fixed !important;
    top: auto !important;
    left: auto !important;
    bottom: auto !important;
    right: auto !important;
    margin: 5px 0 0 -8px !important;
    border-radius: 10px !important;
    z-index: 999999;
}

.location-select-dropdown-post-login .choices__list {
    max-height: 160px;
    overflow: auto;
}

.location-select-dropdown-post-login
    .choices__input.choices__input--cloned:focus {
    outline: none !important;
    border: none !important;
    --tw-ring-shadow: 0;
}

.Toastify__toast-container--top-center {
    top: 68px !important;
}

[type]:focus {
    @apply !border-primary-500 !ring-primary-500;
}

.custom-phone-input [type='tel']:focus {
    @apply  border-2 ring-2;
  }

.bg-referrral-feature {
    background: linear-gradient(
        165deg,
        theme('colors.basicWhite') 0%,
        /* TODO: Need to replace these color from colors in desin system */
            #efefff 17.02%,
        #dddcff 100%
    );
}

.bg-our-milestone-about-us {
    background: linear-gradient(
        180deg,
        /* TODO: Need to replace these color from colors in desin system */
            #efefff 17.02%,
        #f3f2ff 0%,
        rgba(228, 227, 247, 0) 100%
    );
}

.bg-about-us-overview-top {
    background: linear-gradient(
        0deg,
        /* TODO: Need to replace these color from colors in desin system */
            #edfff6 0%,
        theme('colors.basicWhite') 100%
    );
}

@layer components {
    .bg-credit-assessment-card {
        background: linear-gradient(
            133deg,
            rgba(255, 255, 255, 0.51) 0%,
            rgba(255, 255, 255, 0.18) 41.71%,
            rgba(255, 255, 255, 0.06) 95.83%
        );
        box-shadow: 0px 1px 24px -1px rgba(0, 0, 0, 0.18);
        backdrop-filter: blur(12px);
    }
}

.shadow-focus-input-radios-none[type='radio']:focus {
    box-shadow: none !important;
}

.refer-and-earn-gradient {
    background: linear-gradient(
        234deg,
        theme('colors.secondary.500') 0%,
        theme('colors.primary.500') 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.thin-scrollbar::-webkit-scrollbar:vertical {
    width: 8px;
}

.thin-scrollbar::-webkit-scrollbar {
    -webkit-appearance: none;
}

.thin-scrollbar::-webkit-scrollbar:vertical {
    width: 8px;
}

.thin-scrollbar::-webkit-scrollbar:horizontal {
    height: 8px;
}

.thin-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    @apply bg-gray-300 border-2 border-solid border-basicWhite;
}

.thin-scrollbar::-webkit-scrollbar-track {
    border-radius: 10px;
    @apply bg-basicWhite;
}

.thin-scrollbar {
    scrollbar-color: theme('colors.gray[300]') theme('colors.basicWhite');
    scrollbar-width: thin;
}

.bg-homepage-features-bg-gradient {
    background: linear-gradient(180deg, #fff -33.78%, #eef 99.56%);
}

.homepage-bg-banner-default {
    background-image: url('/images/homepage/home-page-banner-v3.webp')!important;
}

.survey-page-bg {
    background: linear-gradient(180deg, #e9faf1 0%, #f6f6f6 100%);
}

/* Opportunty details V2 */

.opportunity-key-investment-highlights-wrapper li,
.opportunity-key-performance-matrics-wrapper li {
    @apply p5-regular;
}

.opportunity-key-performance-matrics-wrapper p:first-child {
    @apply p3-semibold text-basicBlack;
}

.opportunity-key-performance-matrics-wrapper p:first-child {
    @apply p3-semibold text-basicBlack;
}

.tabs-active-left-slice-indicator,
.tabs-active-right-slice-indicator {
    transition: 0.3s ease-in;
    opacity: 1;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-fill-mode: none;
    position: relative;
}

.tabs-active-left-slice-indicator {
    animation-name: fade-in-tab-active;
}

.tabs-active-right-slice-indicator {
    animation-name: fade-in-right-tab-active;
}

.tabs-inactive-left-slice-indicator {
    opacity: 0;
}

@keyframes fade-in-tab-active {
    0% {
        opacity: 0;
        left: -12px;
    }
    100% {
        opacity: 1;
        left: 0;
    }
}

@keyframes fade-in-right-tab-active {
    0% {
        opacity: 0;
        right: -12px;
    }
    100% {
        opacity: 1;
        right: 0;
    }
}

.opportunity-about-section-wrapper div:nth-child(2) {
    @apply p-0 my-4;
}

.opportunity-about-section-wrapper ul,
.opportunity-about-section-wrapper div:nth-child(2) p,
.opportunity-summary-v2 p, .opportunity-summary-v2 ul,
.opportunity-key-investment-highlights-wrapper table tbody {
    @apply p5-regular text-gray-900;
}

@layer components {
    .scroll-horizontal-width-0::-webkit-scrollbar:horizontal {
        height: 0px;
        display:none;
    }

    .scroll-vertical-width-0::-webkit-scrollbar:vertical {
        height: 0px;
        width: 0;
    }

    .scroll-vertical-width-25px::-webkit-scrollbar:vertical {
        width: 25px;
    }
}

.opportunityDetails ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 10px theme('colors.gray.200') !important;
}

.opportunity-key-investment-highlights-wrapper table {
    @apply md:w-full w-max;
}

.snapshot-elements-wrapper .visible-snapshot-0NoCol .snapshot-element-tooltip {
    left: 50%;
    right: auto;
}

.snapshot-elements-wrapper .visible-snapshot-1NoCol .snapshot-element-tooltip {
    left: auto;
    right: auto;
}

.snapshot-elements-wrapper .visible-snapshot-2NoCol .snapshot-element-tooltip {
    left: auto;
    right: 50%;
}

.snapshot-elements-wrapper .visible-snapshot-3NoCol .snapshot-element-tooltip {
    left: auto;
    right: 50%;
}

.snapshot-child {
    @apply border-b border-gray-200;
}

@media (max-width: 767px) {
    .snapshot-elements-wrapper .visible-snapshot-0NoCol .snapshot-element-tooltip,
    .snapshot-elements-wrapper .visible-snapshot-2NoCol .snapshot-element-tooltip {
        left: -48px;
        right: auto;
    }
    
    .snapshot-elements-wrapper .visible-snapshot-1NoCol .snapshot-element-tooltip,
    .snapshot-elements-wrapper .visible-snapshot-3NoCol .snapshot-element-tooltip {
        left: auto;
        right: -48px;
    }

    /* Remove bottom border for the last 2 children in each column for odd numbers */
    .snapshot-child:nth-last-child(-n+2):nth-child(odd) {
        border-bottom: none;
    }

    /* Remove bottom border for the last child in the entire grid for even numbers */
    .snapshot-child:nth-last-child(-n+1):nth-child(even) {
        border-bottom: none;
    }
}


/* Opportunty details */


.fc-widget-normal{
    @apply md:!bottom-[68px] xl:!bottom-9;
}

.no-tap-highlight-color {
    -webkit-tap-highlight-color: transparent;
}

.opportunity-cms-preview strong{
    font-weight: 600!important;
}

.blog-details .main-content p>a{
   @apply break-words;
}

.opportunity-graph > span {
    overflow: visible !important;
}

.navigation-panel.animate__slideInRight.animate__animated{
    --animate-duration: 0.5s;
}

.bottom-navigation-panel.animate__slideInUp.animate__animated{
    --animate-duration: 0.5s;
}

.chat-fc-form-outer {
    visibility: hidden !important;
}

@media (max-width: 640px) {
    .chat-fc-form-outer {
        visibility: hidden !important;
        max-width: 300px !important;
        height: auto !important;
        right: 16px !important;
        left: auto !important;
        bottom: 16px !important;
    }
}

.fc-form {
    padding-top: 16px !important;
    font-size: 14px;
    color: theme('colors.basicBlack') !important;
}

.fc-form > p {
    font-weight: 400 !important;
    color: theme('colors.gray.600') !important;
}

.fc-header {
    font-size: 18px !important;
    font-weight: 600 !important;
}

.pre-fc-field > input {
    padding: 12px !important;
}

.security-select-dropdown-deal-live #react-select-2-listbox .css-qr46ko{
    @apply max-h-[100px] overflow-y-auto;
}